<template>
    <v-sheet color="white"
             class="py-4 px-0"
             elevation="1">
        <ValidationObserver ref="obs">
            <div class="px-4" xs12>
                <v-layout align-center
                          :wrap="isMobile">
                    <v-flex sm6
                            :class="{'pr-4': !isMobile}">
                        <Autocomplete rules="required"
                                      :label="$t('labels.incident_type')"
                                      name="incidentTypeId"
                                      clearable
                                      :items="filterableTypesList"
                                      :loading="isTypesLoading"
                                      :search-input.sync="searchTypes"
                                      @click:clear="getIncidentsTypes"
                                      @load-more-items="getIncidentsTypesLazyLoading"
                                      v-model="incidentTypeId"></Autocomplete>
                    </v-flex>
                    <v-flex sm6
                            :class="{'pl-4': !isMobile}">
                        <TextField :rules="{ max: 50, required: true }"
                                   :label="$t('labels.report_name')"
                                   v-model="name"
                                   name="name"></TextField>
                    </v-flex>
                </v-layout>
                <v-layout align-stretch
                          v-if="showFields"
                          :wrap="isMobile">
                    <v-flex sm6
                            :class="{'pr-4': !isMobile}">
                        <FieldsetGroup :label="$t('labels.incident_fields')">
                            <template slot="content">
                                <Checkbox v-model="incidentFields[item.value]"
                                          v-for="(item, i) in incidentFieldsConfiguration"
                                          :key="i"
                                          :label="item.text"></Checkbox>
                            </template>
                        </FieldsetGroup>
                    </v-flex>
                    <v-flex sm6
                            :class="{'pl-4': !isMobile}">
                        <FieldsetGroup :label="$t('labels.template_fields')"
                                       :class="{'mt-4': isMobile}">
                            <template slot="content">
                                <Checkbox v-model="templateQuestions"
                                          v-for="(item, i) in filterableTemplateQuestions"
                                          :key="i"
                                          multiple
                                          :id="item.value"
                                          :input-value="item.text"
                                          :label="item.text"></Checkbox>
                            </template>
                        </FieldsetGroup>
                        <RadioGroup :label="$t('labels.filters')"
                                    :options="dates"
                                    class="mt-5"
                                    v-model="dateType"
                                    name="dateType"></RadioGroup>
                        <v-layout v-if="showDatePickers"
                                  :wrap="isMobile"
                                  :class="{'flex-gap': !isMobile}">
                            <DatePicker v-model="dateFrom"
                                        :clearable="true"
                                        :rules="{'required': showDatePickers}"
                                        name="dateFrom"
                                        :label="$t('labels.date_from')"></DatePicker>
                            <DatePicker v-model="dateTo"
                                        :clearable="true"
                                        :rules="{'required': showDatePickers}"
                                        name="dateTo"
                                        :label="$t('labels.date_to')"></DatePicker>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </div>
        </ValidationObserver>
    </v-sheet>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { ValidationObserver } from 'vee-validate';
import RadioGroup from '@/components/widgets/forms/RadioGroup';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import TextField from '@/components/widgets/forms/TextField';
import Checkbox from '@/components/widgets/forms/Checkbox';
import DatePicker from '@/components/widgets/forms/DatePicker';
import FieldsetGroup from '@/components/widgets/forms/FieldsetGroup';
import debouncedTemplates from '@/mixins/debounced/debouncedTemplates';
import incidentFormFields from '@/mixins/incidentFormFields';
import debouncedIncidentTypes from '@/mixins/debounced/debouncedIncidentTypes';
import { DATES_CONFIGURATIONS } from '@/constants/customReports';

export default {
    name: 'ManageCustomReportForm',
    mixins: [debouncedTemplates, incidentFormFields, debouncedIncidentTypes],
    components: { ValidationObserver, RadioGroup, Autocomplete, TextField, FieldsetGroup, Checkbox, DatePicker },
    props: {
        isEdit: {
            type: Boolean,
            'default': false
        }
    },
    computed: {
        ...mapFields('customReports', [
            'reportData.incidentTypeId',
            'reportData.templateId',
            'reportData.name',
            'reportData.incidentFields',
            'reportData.templateQuestions',
            'reportData.dateType',
            'reportData.dateFrom',
            'reportData.dateTo'
        ]),
        ...mapGetters('templates', [
            'filterableTemplates',
            'filterableTemplateQuestions'
        ]),
        ...mapGetters('incidents', [
            'filterableTypesList'
        ]),
        ...mapState('customReports', [
            'reportFormVisibility'
        ]),
        ...mapState('incidents', [
            'incidentTypes'
        ]),
        ...mapState('templates', [
            'totalTemplatesAmount'
        ]),
        isMobile () {
            return this.$vuetify.breakpoint.xs;
        },
        isFormDirty () {
            return this.$refs.obs.flags.changed;
        },
        showFields () {
            return !!this.templateId;
        },
        dates () {
            return [
                {
                    value: DATES_CONFIGURATIONS.LAST_MONTH,
                    text: this.$t('labels.date_config.last_month')
                },
                {
                    value: DATES_CONFIGURATIONS.PREVIOUS_MONTH,
                    text: this.$t('labels.date_config.previous_month')
                },
                {
                    value: DATES_CONFIGURATIONS.THIS_YEAR,
                    text: this.$t('labels.date_config.this_year')
                },
                {
                    value: DATES_CONFIGURATIONS.ANY,
                    text: this.$t('labels.date_config.any')
                },
                {
                    value: DATES_CONFIGURATIONS.CUSTOM_DATE,
                    text: this.$t('labels.date_config.custom_date')
                }
            ];
        },
        showDatePickers () {
            return this.dateType === DATES_CONFIGURATIONS.CUSTOM_DATE;
        }
    },
    watch: {
        reportFormVisibility: {
            handler (val) {
                if (val) {
                    this.getIncidentsTypes();
                    if (!this.isEdit) {
                        this.dateType = DATES_CONFIGURATIONS.LAST_MONTH;
                    }
                }
            },
            immediate: true
        },
        incidentTypeId: {
            handler (val, oldVal) {
                if (val && val !== oldVal) {
                    this.templateId = this.incidentTypes.find(el => el.id === val)?.templateId;
                    this.getQuestions(this.templateId);
                    this.templateQuestions = [];
                }
            }
        },
        showFields: {
            handler (val) {
                if (!val) {
                    this.templateQuestions = [];
                    this.$store.commit('customReports/RESET_INCIDENT_FIELDS');
                }
            }
        },
        showDatePickers: {
            handler (val) {
                if (!val) {
                    this.dateFrom = null;
                    this.dateTo = null;
                }
            }
        }
    },
    methods: {
        getQuestions (templateId) {
            const params = {
                params: {
                    'filter[template_id]': templateId
                }
            };

            return this.$store.dispatch('templates/getTemplateQuestions', params);
        },
        resetValidation () {
            return this.$refs.obs.reset();
        },
        validate () {
            return this.$refs.obs.validate();
        }
    }
};
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";

    ::v-deep {
        & .v-input--checkbox {
            margin-top: 0;
            & .v-messages {
                display: none;
            }
        }

        & .v-input--is-disabled {
            & .v-messages__message {
                font-size: $xxs;
            }
        }
    }

    .flex-gap {
        gap: 16px;
    }
</style>
