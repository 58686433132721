var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{staticClass:"mb-3",attrs:{"justify-end":""}},[_c('v-btn',{attrs:{"color":"secondary","data-test":"addNewCustomReport"},on:{"click":function($event){return _vm.openEditForm(null)}}},[_vm._v(_vm._s(_vm.$t('buttons.add_custom_report'))+" ")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"hide-default-header":_vm.$vuetify.breakpoint.smAndUp,"loading":_vm.loader,"items":_vm.customReports,"disable-sort":"","disable-pagination":"","hide-default-footer":""},on:{"dblclick:row":_vm.showRowDetails},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.smAndUp)?{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('thead',[_c('tr',_vm._l((props.headers),function(headerItem,index){return _c('th',{key:index},[(headerItem.value === 'data-table-select')?_c('v-simple-checkbox',{attrs:{"ripple":false,"indeterminate":props.someItems && !props.everyItem,"color":"purple"},on:{"input":on['toggle-select-all']},model:{value:(props.everyItem),callback:function ($$v) {_vm.$set(props, "everyItem", $$v)},expression:"props.everyItem"}}):_c('SortableHeader',{attrs:{"header-item":headerItem},on:{"sort":_vm.sort}})],1)}),0)])]}}:null,{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"clickable",on:{"click":function($event){return _vm.showDetails(item)}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('HintingIcon',{attrs:{"class-name":"mr-2","icon":_vm.ICONS.DETAILS,"data-test":"showDetailsBtn","tooltip":_vm.$t('actions.view')},on:{"click":function($event){return _vm.showDetails(item)}}}),_c('HintingIcon',{attrs:{"class-name":"mr-2","icon":_vm.ICONS.EDIT,"data-test":"editReportBtn","tooltip":_vm.$t('actions.edit')},on:{"click":function($event){return _vm.openEditForm(item)}}}),_c('ButtonWithConfirmation',{attrs:{"item-id":item.id,"callback":_vm.deleteCustomReport}})]}}],null,true)}),_c('Pagination',{attrs:{"pagination":_vm.pagination,"data-test":"pagination"},on:{"change-page":function($event){return _vm.changePage($event)},"change-amount":function($event){return _vm.changeAmount($event)}}}),_c('SingleCustomReport',{attrs:{"dialog":_vm.showSingleReport,"title":_vm.singleReportTitle,"item-id":_vm.singleReportId},on:{"visibility":function($event){_vm.showSingleReport = $event},"close":_vm.closeDetails}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }