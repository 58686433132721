<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-layout justify-end class="mb-3">
            <v-btn color="secondary"
                   data-test="addNewCustomReport"
                   @click="openEditForm(null)">{{ $t('buttons.add_custom_report') }}
            </v-btn>
        </v-layout>

        <v-data-table :headers="headers"
                      :hide-default-header="$vuetify.breakpoint.smAndUp"
                      :loading="loader"
                      :items="customReports"
                      disable-sort
                      disable-pagination
                      hide-default-footer
                      @dblclick:row="showRowDetails"
                      class="elevation-1">
                    <template v-slot:header="{ props, on }"
                              v-if="$vuetify.breakpoint.smAndUp">
                        <thead>
                        <tr>
                            <th v-for="(headerItem, index) in props.headers"
                                :key="index">
                                <v-simple-checkbox
                                    :ripple="false"
                                    @input="on['toggle-select-all']"
                                    v-if="headerItem.value === 'data-table-select'"
                                    v-model="props.everyItem"
                                    :indeterminate="props.someItems && !props.everyItem"
                                    color="purple"
                                ></v-simple-checkbox>
                                <SortableHeader v-else :header-item="headerItem"
                                                @sort="sort"></SortableHeader>
                            </th>
                        </tr>
                        </thead>
                    </template>
            <template v-slot:item.name="{ item }">
                <span class="clickable"
                      @click="showDetails(item)">{{ item.name }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <HintingIcon class-name="mr-2"
                             :icon="ICONS.DETAILS"
                             data-test="showDetailsBtn"
                             @click="showDetails(item)"
                             :tooltip="$t('actions.view')"></HintingIcon>

                <HintingIcon class-name="mr-2"
                             :icon="ICONS.EDIT"
                             @click="openEditForm(item)"
                             data-test="editReportBtn"
                             :tooltip="$t('actions.edit')"></HintingIcon>

                <ButtonWithConfirmation :item-id="item.id"
                                        :callback="deleteCustomReport"></ButtonWithConfirmation>
            </template>
        </v-data-table>

        <Pagination :pagination="pagination"
                    data-test="pagination"
                    @change-page="changePage($event)"
                    @change-amount="changeAmount($event)"></Pagination>

        <SingleCustomReport :dialog="showSingleReport"
                            :title="singleReportTitle"
                            :item-id="singleReportId"
                            @visibility="showSingleReport = $event"
                            @close="closeDetails"></SingleCustomReport>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Pagination from '@/components/widgets/tables/Pagination';
import HintingIcon from '@/components/widgets/HintingIcon';
import { ICONS } from '@/constants/icons';
import ButtonWithConfirmation from '@/components/widgets/buttons/ButtonWithConfirmation';
import SingleCustomReport from '@/components/reports/singleCustomReport/SingleCustomReport';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import paginationSettings from '@/mixins/paginationSettings';

export default {
    name: 'CustomReportsTable',
    components: { Pagination, HintingIcon, ButtonWithConfirmation, SingleCustomReport, SortableHeader },
    mixins: [paginationSettings],
    props: {
        loader: {
            type: Boolean,
            'default': false
        },
        changedPage: {
            type: [String, Number],
            required: false
        }
    },
    data: (vm) => ({
        pagination: vm.setupPagination(),
        ICONS,
        showSingleReport: false,
        singleReportTitle: '',
        singleReportId: -1,
        sortable: {
            name: 'desc'
        }
    }),
    computed: {
        headers () {
            return [
                {
                    text: this.$t('incidents.table.headers.seq'),
                    value: 'seq',
                    width: '80px',
                    sortable: false
                },
                {
                    text: this.$t('labels.report_name'),
                    value: 'name',
                    sortable: true
                },
                {
                    text: this.$t('labels.actions'),
                    value: 'actions',
                    width: '120px',
                    sortable: false
                }
            ];
        },
        ...mapState('customReports', [
            'customReports',
            'totalCustomReportsAmount',
            'activeCustomReport'
        ]),
        ...mapState('incidents', [
            'incidentSingleType'
        ])
    },
    watch: {
        totalCustomReportsAmount: {
            handler (val) {
                this.pagination.amountAll = val;
            },
            immediate: true
        }
    },
    methods: {
        openEditForm (item) {
            if (item) {
                this.$store.dispatch('customReports/getActiveCustomReport', item.id)
                    .then(() => {
                        this.$store.dispatch('incidents/getSingleIncidentType', this.activeCustomReport.incidentTypeId)
                            .then(() => {
                                this.$store.commit('customReports/SET_REPORT_DATA', {
                                    ...this.activeCustomReport,
                                    templateId: this.incidentSingleType.templateId
                                });
                                const params = {
                                    params: {
                                        'filter[template_id]': this.incidentSingleType.templateId
                                    }
                                };

                                this.$store.dispatch('templates/getTemplateQuestions', params)
                                    .then(() => {
                                        this.$store.commit('customReports/SET_REPORT_FORM_VISIBILITY', true);
                                    });
                            });
                    });
            } else {
                this.$store.commit('customReports/SET_REPORT_FORM_VISIBILITY', true);
            }
        },
        changePage (event) {
            this.pagination.page = event;
            this.$emit('change-page', event);
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.$emit('change-amount', event);
        },
        deleteCustomReport (id) {
            return this.$store.dispatch('customReports/deleteCustomReport', id)
                .then(() => {
                    this.$emit('fetch');
                });
        },
        showDetails (item) {
            this.showSingleReport = true;
            this.singleReportTitle = item.name;
            this.singleReportId = item.id;
        },
        showRowDetails (event, { item }) {
            this.showDetails(item);
        },
        closeDetails () {
            this.showSingleReport = false;
            this.singleReportTitle = '';
            this.singleReportId = -1;
            this.$store.commit('customReports/SET_SINGLE_CUSTOM_REPORT', null);
        },
        sort (event) {
            const val = event;
            switch (this.sortable[val]) {
            case null:
                this.sortable[val] = 'asc';
                break;
            case 'asc':
                this.sortable[val] = 'desc';
                break;
            case 'desc':
                this.sortable[val] = 'asc';
                break;
            }

            this.$emit('sort', {
                name: val,
                value: this.sortable[val]
            });
        }
    }
};
</script>

<style scoped>

</style>
