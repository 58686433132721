<template>
    <ValidationProvider
        :name="label"
        :vid="id"
        :rules="required ? {required: true} : {}"
        v-slot="{ errors }"
        v-if="(readonly && value) || (!readonly && !notApplicable)"
    >
        <v-radio-group
            v-model="questionValue"
            :error="incorrect"
            :error-messages="errors"
            :name="id"
        >
            <v-radio v-for="n in options"
                     :key="n"
                     :disabled="readonly"
                     :value="n">
                <template slot="label">
                    <span class="text--xs"
                          :class="colorClass">{{ n }}</span>
                </template>
            </v-radio>
        </v-radio-group>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    name: 'SingleChoice',
    components: { ValidationProvider },
    data: () => ({
        questionValue: null
    }),
    props: {
        id: {
            type: String
        },
        label: {
            type: String,
            'default': ''
        },
        options: {
            type: Array,
            required: true
        },
        value: {
            type: String,
            'default': ''
        },
        incorrect: {
            type: Boolean,
            'default': false
        },
        readonly: {
            type: Boolean,
            'default': true
        },
        required: {
            type: Boolean,
            'default': false
        },
        notApplicable: {
            type: Boolean,
            'default': false
        }
    },
    computed: {
        colorClass () {
            return this.incorrect ? 'red--text font-weight-medium' : 'primary--text';
        }
    },
    mounted () {
        this.questionValue = this.value;
    }
};
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";
    ::v-deep {
        & .v-input--selection-controls {
            margin-top: 0;
        }
        & .v-input--radio-group__input {
            cursor: default;
        }
        & .v-radio--is-disabled {
            margin-bottom: 0 !important;
            & .v-icon {
                font-size: 18px;
                color: $primary !important;
                &.error--text {
                    color: $red !important;
                }
            }
        }
    }
</style>
