<template>
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.custom_reports') }}</h1>
        <v-col cols="12" md="6">
            <TextField v-model.trim="search"
                       clearable
                       @click:clear="search = ''"
                       :label="$t('labels.name')"
                       slotName="append">
                <template slot="slotContent">
                    <v-icon color="medium_text">{{ ICONS.SEARCH }}</v-icon>
                </template>
            </TextField>
        </v-col>
        <CustomReportsTable @change-page="changePage($event)"
                            @change-amount="changeAmount($event)"
                            @fetch="fetchCustomReports"
                            @sort="sortEvent($event)"
                            data-test="customReportsTable"
                            :changed-page="pagination.page"
                            :loader="loader"></CustomReportsTable>
        <ManageCustomReport @fetch="fetchCustomReports"
                            v-if="reportFormVisibility"></ManageCustomReport>
    </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapState } from 'vuex';
import { ICONS } from '@/constants/icons';
import TextField from '@/components/widgets/forms/TextField';
import CustomReportsTable from '@/components/reports/CustomReportsTable';
import ManageCustomReport from '@/components/reports/manageCustomReport/ManageCustomReport';

export default {
    name: 'CustomReports',
    components: { CustomReportsTable, ManageCustomReport, TextField },
    data: () => ({
        ICONS,
        pagination: {
            page: 1,
            amount: 20
        },
        loader: false,
        sortingColumn: null,
        search: ''
    }),
    computed: {
        ...mapState('customReports', [
            'reportFormVisibility'
        ])
    },
    watch: {
        search: {
            handler (val, oldVal) {
                if ((val && val.length >= 2) || (oldVal && val === '')) {
                    this.pagination.page = 1;
                    this.getLazyData();
                }
            },
            immediate: true
        },
        watch: {
            sortingColumn: {
                handler () {
                    this.getLazyData();
                }
            },
            deep: true
        }
    },
    methods: {
        getLazyData: debounce(function () {
            this.fetchCustomReports();
        }, 1000),

        fetchCustomReports () {
            this.loader = true;

            const params = {
                'page[number]': this.pagination.page,
                'page[size]': this.pagination.amount
            };

            if (this.search) {
                params['filter[name][match]'] = this.search;
            }

            if (this.sortingColumn) {
                params.sort = this.sortingColumn.value === 'asc' ? this.sortingColumn.name : `-${this.sortingColumn.name}`;
            }

            return this.$store.dispatch('customReports/getCustomReports', params)
                .finally(() => {
                    this.loader = false;
                });
        },
        changePage (event) {
            this.pagination.page = event;
            this.fetchCustomReports();
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.fetchCustomReports();
        },
        sortEvent (event) {
            this.sortingColumn = event;
            this.fetchCustomReports();
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.CUSTOM_REPORTS)) {
                vm.fetchCustomReports();
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    }
};
</script>

<style scoped>

</style>
