<template>
    <div v-if="readonly">
        <v-progress-linear
            v-if="value"
            :value="value"
            :color="color"
            rounded
            height="16">
            <span>{{ Math.ceil(value) }}%</span>
        </v-progress-linear>
        <span v-else>{{ value }}</span>
    </div>
    <ValidationProvider
        v-else-if="!notApplicable"
        :name="label"
        :vid="id"
        :rules="required ? {required: true} : {}"
        v-slot="{ errors }"
    >
        <v-slider
            min="0"
            max="100"
            v-model="questionValue"
            :name="id"
            thumb-label
            :error-messages="errors"
        ></v-slider>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    name: 'Percent',
    components: { ValidationProvider },
    data: () => ({
        questionValue: null
    }),
    props: {
        id: {
            type: String
        },
        value: {
            type: [String, Number],
            'default': null
        },
        label: {
            type: String
        },
        incorrect: {
            type: Boolean,
            'default': false
        },
        readonly: {
            type: Boolean,
            'default': true
        },
        required: {
            type: Boolean,
            'default': false
        },
        notApplicable: {
            type: Boolean,
            'default': false
        }
    },
    computed: {
        color () {
            return this.incorrect ? 'medium_red' : 'secondary';
        }
    },
    created () {
        this.questionValue = +this.value;
    }
};
</script>
