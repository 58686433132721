<template>
    <div class="d-inline-block">
        <v-icon v-if="iconed"
                size="24"
                class="clickable d-inline-block"
                @click="showImage">{{ previewIcon }}</v-icon>
        <v-img :src="baseSrc"
               v-else
               @click="showImage"
               class="preview"></v-img>

        <Modal :dialog="dialog"
               @visibility="dialog = $event"
               :max-width="maxWidth"
               @close="dialog = false">
            <template slot="content">
                <v-img :src="baseSrc"></v-img>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/templates/Modal';
import { ICONS } from '@/constants/icons';
import { getMimeType, createBase64WithMimeType } from '@/helpers/files';

export default {
    name: 'PreviewImage',
    components: { Modal },
    props: {
        src: {
            type: String,
            required: true
        },
        iconed: {
            type: Boolean,
            'default': false
        },
        icon: {
            type: String,
            'default': ''
        },
        maxWidth: {
            type: String,
            'default': '800px'
        }
    },
    data: () => ({
        dialog: false,
        baseSrc: ''
    }),
    computed: {
        previewIcon () {
            return this.icon || ICONS.FILE;
        }
    },
    methods: {
        showImage () {
            this.dialog = true;
        },
        getSrc () {
            if (getMimeType(this.src)) {
                this.baseSrc = this.src;
            } else {
                createBase64WithMimeType(this.src)
                    .then(result => {
                        this.baseSrc = `data:${result.mime};base64,${this.src}`;
                    });
            }
        }
    },
    created () {
        this.getSrc();
    }
};
</script>

<style scoped lang="scss">
    .preview {
        display: inline-block;
        max-width: 100px;
        cursor: pointer;
    }
</style>
