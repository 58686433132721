<template>
    <div class="py-1">
        <v-progress-linear :value="status.completion_percent || status.percentage"
                           :color="color"
                           rounded
                           height="15"></v-progress-linear>
        <p class="mb-0 mt-1 text-center text--xs">{{ status.name }} ({{ status.completion_percent || status.percentage }}%)</p>
    </div>
</template>

<script>
import { STATUSES_COLOR } from '@/constants/defaultIncidentColors';

export default {
    name: 'Status',
    props: {
        status: {
            type: Object,
            required: true
        }
    },
    computed: {
        color () {
            return this.status.color || STATUSES_COLOR[this.status.name];
        }
    }
};
</script>

<style scoped>

</style>
