<template>
    <Modal :modal-title="title"
           :dialog="reportFormVisibility"
           max-width="1000px"
           dense
           :persistent="true"
           :closeIcon="false"
           @keydown.enter="showConfirmation"
           @keydown.esc="closeWithConfirmation"
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <ManageCustomReportForm ref="form"
                                    :is-edit="isEdit"></ManageCustomReportForm>

            <ConfirmationDialog :dialog="showConfirmationDialog"
                                data-test="ConfirmationDialog"
                                @confirm="handleConfirmation($event)"
                                :text="$t('messages.save_changes')"
                                :action-button-title="$t('buttons.save')"></ConfirmationDialog>
            <span data-test="testKeydownSpan"></span>

            <ValidationError v-if="showError"></ValidationError>
        </template>

        <template slot="actions">
            <v-layout align-center
                      justify-end
                      class="mt-3">
                <v-btn color="secondary"
                       outlined
                       class="mr-3"
                       data-test="closeBtn"
                       @click="close">{{ $t('buttons.cancel') }}</v-btn>
                <v-btn color="secondary"
                       data-test="saveBtn"
                       :loading="loader"
                       :disabled="loader"
                       @click="save">{{ $t('buttons.save') }}</v-btn>
            </v-layout>
        </template>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/templates/Modal';
import ManageCustomReportForm from '@/components/reports/manageCustomReport/ManageCustomReportForm';
import ConfirmationDialog from '@/components/widgets/dialogs/ConfirmationDialog';
import ValidationError from '@/components/widgets/ValidationError';

export default {
    name: 'ManageCustomReport',
    components: { Modal, ManageCustomReportForm, ConfirmationDialog, ValidationError },
    data: () => ({
        loader: false,
        showConfirmationDialog: false,
        showError: false
    }),
    computed: {
        ...mapState('customReports', [
            'reportFormVisibility',
            'activeCustomReport'
        ]),
        isEdit () {
            return !!this.activeCustomReport?.id;
        },
        title () {
            return this.isEdit ? this.$t('custom_reports.edit_title') : this.$t('custom_reports.add_title');
        },
        isFieldsDirty () {
            return this.$refs.form.isFormDirty;
        }
    },
    methods: {
        close () {
            this.$emit('close');
            this.$store.commit('customReports/SET_REPORT_FORM_VISIBILITY', false);
            this.$store.commit('incidents/SET_INCIDENT_SINGLE_TYPE', null);
            this.$store.commit('customReports/RESET_REPORT_DATA');
            this.$refs.form.resetValidation();
        },
        visibility (event) {
            this.$emit('visibility', event);
        },
        createCustomReport () {
            this.loader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        return this.$store.dispatch('customReports/createCustomReport')
                            .then(() => {
                                this.$emit('fetch');
                                this.close();
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        updateCustomReport () {
            this.loader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        return this.$store.dispatch('customReports/updateCustomReport')
                            .then(() => {
                                this.$emit('fetch');
                                this.close();
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        save () {
            if (this.isEdit) {
                this.updateCustomReport();
            } else {
                this.createCustomReport();
            }
        },
        showConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            }
        },
        closeWithConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            } else {
                this.close();
            }
        },
        handleConfirmation () {
            if (event) {
                this.save();
                this.showConfirmationDialog = false;
            } else {
                this.showConfirmationDialog = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
