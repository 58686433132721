<template>
    <span v-if="readonly" :class="colorClass">{{ questionAnswer }}</span>
    <ValidationProvider v-else-if="!notApplicable"
                        :name="label"
                        :vid="id"
                        :rules="required ? {required: true} : {}"
                        v-slot="{ errors }">
        <v-radio-group
            v-model="questionValue"
            :error="incorrect"
            :error-messages="errors"
            :name="id"
        >
            <v-radio :disabled="readonly" :value="1">
                <template slot="label">
                    <span class="text--xs" :class="colorClass">{{ $t('answers.yes') }}</span>
                </template>
            </v-radio>
            <v-radio :disabled="readonly" :value="0">
                <template slot="label">
                    <span class="text--xs" :class="colorClass">{{ $t('answers.no') }}</span>
                </template>
            </v-radio>
        </v-radio-group>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    name: 'YesNo',
    components: { ValidationProvider },
    data: () => ({
        questionValue: null
    }),
    props: {
        id: {
            type: String
        },
        value: {
            type: String,
            'default': ''
        },
        label: {
            type: String
        },
        incorrect: {
            type: Boolean,
            'default': false
        },
        readonly: {
            type: Boolean,
            'default': true
        },
        required: {
            type: Boolean,
            'default': false
        },
        notApplicable: {
            type: Boolean,
            'default': false
        }
    },
    computed: {
        colorClass () {
            return this.incorrect ? 'red--text font-weight-medium' : 'primary--text';
        },
        questionAnswer () {
            switch (this.questionValue) {
            case '1':
                return this.$t('answers.yes');
            case '0':
                return this.$t('answers.no');
            default:
                return '';
            }
        }
    },
    created () {
        this.questionValue = this.value;
    }
};
</script>
