import { INCIDENT_CONFIGURATION } from '@/constants/incidentConfiguration';

export default {
    computed: {
        incidentFieldsConfiguration () {
            return {
                number: {
                    value: INCIDENT_CONFIGURATION.NUMBER,
                    text: this.$t('incidents.table.headers.number')
                },
                customer: {
                    value: INCIDENT_CONFIGURATION.CUSTOMER,
                    text: this.$t('labels.customer')
                },
                site: {
                    value: INCIDENT_CONFIGURATION.SITE,
                    text: this.$t('labels.site')
                },
                location: {
                    value: INCIDENT_CONFIGURATION.LOCATION,
                    text: this.$t('labels.location')
                },
                startDate: {
                    value: INCIDENT_CONFIGURATION.START_DATE,
                    text: this.$t('labels.occurrence_date')
                },
                endDate: {
                    value: INCIDENT_CONFIGURATION.END_DATE,
                    text: this.$t('labels.end_date')
                },
                latitude: {
                    value: INCIDENT_CONFIGURATION.LATITUDE,
                    text: this.$t('labels.lat')
                },
                longitude: {
                    value: INCIDENT_CONFIGURATION.LONGITUDE,
                    text: this.$t('labels.long')
                },
                type: {
                    value: INCIDENT_CONFIGURATION.TYPE,
                    text: this.$t('labels.incident_type')
                },
                status: {
                    value: INCIDENT_CONFIGURATION.STATUS,
                    text: this.$t('labels.status')
                },
                employee: {
                    value: INCIDENT_CONFIGURATION.EMPLOYEE,
                    text: this.$t('incidents.table.headers.person')
                },
                responsibleEmployee: {
                    value: INCIDENT_CONFIGURATION.RESPONSIBLE_EMPLOYEE,
                    text: this.$t('labels.responsible_person')
                }
            };
        }
    }
};
