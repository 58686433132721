<template>
    <div>
        <PreviewImage v-for="(pic, i) in pictures"
                      class="mr-1"
                      :key="i"
                      :src="pic.picture"></PreviewImage>
    </div>
</template>

<script>
import PreviewImage from '@/components/widgets/PreviewImage';

export default {
    name: 'PicturesAnswers',
    components: { PreviewImage },
    props: {
        pictures: {
            type: Array,
            'default': () => ([])
        }
    }
};
</script>

<style scoped>

</style>
