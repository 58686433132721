<template>
    <Modal :modal-title="title"
           :dialog="dialog"
           max-width="1800px"
           dense
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <SingleCustomReportTable :loader="loader"
                                     :pdf-loader="pdfLoader"
                                     :xls-loader="xlsLoader"
                                     :report-title="title"
                                     :filters="mailParams"
                                     @get-pdf-file="getPDF"
                                     @get-xls-file="getXLS"
                                     @change-amount="changeAmount($event)"
                                     @change-page="changePage($event)"></SingleCustomReportTable>
        </template>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/templates/Modal';
import SingleCustomReportTable from '@/components/reports/singleCustomReport/SingleCustomReportTable';

export default {
    name: 'SingleCustomReport',
    components: { Modal, SingleCustomReportTable },
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        title: {
            type: String,
            'default': ''
        },
        itemId: {
            type: [String, Number],
            required: true
        }
    },
    data: () => ({
        pagination: {
            page: 1,
            amount: 20
        },
        loader: false,
        pdfLoader: false,
        xlsLoader: false
    }),
    computed: {
        ...mapState('locale', [
            'currentLocale'
        ]),
        mailParams () {
            return {
                id: this.itemId,
                locale: this.currentLocale
            };
        }
    },
    watch: {
        itemId: {
            handler (val) {
                if (val && val !== -1) {
                    this.fetchSingleCustomReport();
                }
            }
        }
    },
    methods: {
        close () {
            this.$emit('close');
        },
        visibility (event) {
            this.$emit('visibility', event);
        },
        fetchSingleCustomReport () {
            this.loader = true;

            const params = {
                id: this.itemId,
                query: {
                    locale: this.currentLocale,
                    page: this.pagination.page,
                    per_page: this.pagination.amount
                }
            };

            return this.$store.dispatch('customReports/getSingleCustomReport', params)
                .finally(() => {
                    this.loader = false;
                });
        },
        changePage (event) {
            this.pagination.page = event;
            this.fetchSingleCustomReport();
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.fetchSingleCustomReport();
        },
        getPDF () {
            this.pdfLoader = true;

            return this.$store.dispatch('customReports/getCustomReportPdf', {
                id: this.itemId,
                locale: this.currentLocale,
                page: this.pagination.page,
                per_page: this.pagination.amount
            })
                .finally(() => {
                    this.pdfLoader = false;
                });
        },
        getXLS () {
            this.xlsLoader = true;

            return this.$store.dispatch('customReports/getCustomReportXls', {
                id: this.itemId,
                locale: this.currentLocale,
                page: this.pagination.page,
                per_page: this.pagination.amount
            })
                .finally(() => {
                    this.xlsLoader = false;
                });
        }
    }
};
</script>

<style scoped>

</style>
