<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-layout align-center
                  class="mb-8">
            <GetFile :loader="pdfLoader"
                     class="mr-3"
                     @get-file="emitGetPDFFile()"></GetFile>
            <GetFile :title="$t('buttons.export_xls')"
                    :loader="xlsLoader"
                    class="mr-3"
                    @get-file="emitGetXLSFile()"></GetFile>
            <v-btn color="secondary"
                   small
                   @click="showSendMail"
                   class="ml-3">{{ $t('buttons.send_mail') }}</v-btn>
            <SendMail :dialog="sendMailVisibility"
                      :callback="sendMail"
                      :default-title="reportTitle"
                      @close="closeSendMail"
                      @visibility="sendMailVisibility = $event"></SendMail>
        </v-layout>
        <v-data-table :headers="headers"
                      :loading="loader"
                      :items="items"
                      disable-sort
                      disable-pagination
                      hide-default-footer
                      class="elevation-1">
            <template v-slot:item.kind="{ item }">
                <Type :type="item.kind"></Type>
            </template>
            <template v-slot:item.status="{ item }">
                <Status :status="item.status"></Status>
            </template>
            <template v-for="el in answersCount"
                      v-slot:[`item.answers_${el}`]="{ item }">
                <span :key="el">
                    <Component v-if="hasKey(getQuestionType(item.answers, el))"
                               :is="configureComponent(findAnswer(item.answers, el)).name"
                               v-bind="configureComponent(findAnswer(item.answers, el)).attributes"></Component>
                    <span v-else
                          :class="{'red--text fot-weight-medium': item.is_incorrect}">{{ getText(findAnswer(item.answers, el)) }}</span>
                </span>
            </template>
        </v-data-table>
        <Pagination :pagination="pagination"
                    data-test="pagination"
                    @change-page="changePage($event)"
                    @change-amount="changeAmount($event)"></Pagination>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import Pagination from '@/components/widgets/tables/Pagination';
import { TYPES } from '@/constants/questionTypes';
import Rating from '@/components/questions/Rating';
import MultipleChoice from '@/components/questions/MultipleChoice';
import Percent from '@/components/questions/Percent';
import SingleChoice from '@/components/questions/SingleChoice';
import YesNo from '@/components/questions/YesNo';
import PicturesAnswers from '@/components/questions/PicturesAnswers';
import configQuestionComponent from '@/mixins/configQuestionComponent';
import Type from '@/components/widgets/Type';
import Status from '@/components/widgets/Status';
import GetFile from '@/components/widgets/buttons/GetFile';
import SendMail from '@/components/widgets/dialogs/SendMail';

export default {
    name: 'SingleCustomReportTable',
    components: {
        Pagination,
        Rating,
        MultipleChoice,
        Percent,
        SingleChoice,
        YesNo,
        PicturesAnswers,
        Type,
        Status,
        GetFile,
        SendMail
    },
    mixins: [configQuestionComponent],
    props: {
        loader: {
            type: Boolean,
            'default': false
        },
        pdfLoader: {
            type: Boolean,
            'default': false
        },
        xlsLoader: {
            type: Boolean,
            'default': false
        },
        reportTitle: {
            type: String,
            'default': ''
        },
        filters: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        pagination: {
            page: 1,
            amount: 20,
            amountAll: 100
        },
        TYPES,
        sendMailVisibility: false
    }),
    computed: {
        ...mapState('customReports', [
            'singleCustomReport'
        ]),
        ...mapState('generalConfigurations', [
            'host'
        ]),
        ...mapFields('sendMail', [
            'mailData.agency',
            'mailData.kindOfReport',
            'mailData.report'
        ]),
        headers () {
            return this.singleCustomReport?.meta.headers ? this.singleCustomReport.meta.headers.map(el => {
                if (typeof el.value === 'string') {
                    const result = { ...el };
                    if (el.value === 'status') {
                        result.width = '100px';
                    }
                    return result;
                } else {
                    return {
                        text: el.text,
                        value: `answers_${el.value.id}`,
                        answerId: el.value.id
                    };
                }
            }) : [];
        },
        answersCount () {
            if (this.singleCustomReport?.meta.headers?.length > 0) {
                const answers = this.singleCustomReport.meta.data?.filter(el => Object.hasOwnProperty.call(el, 'value') &&
                typeof el.value !== 'string');
                if (answers) {
                    return answers.reduce(
                        (max, character) => (character.value.id > max ? character.value.id : max),
                        answers[0].value.id
                    );
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        },
        items () {
            return this.singleCustomReport?.data || [];
        }
    },
    watch: {
        'singleCustomReport.meta.total_amount': {
            handler (val) {
                this.pagination.amountAll = val;
            },
            immediate: true
        }
    },
    methods: {
        changePage (event) {
            this.pagination.page = event;
            this.$emit('change-page', event);
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.$emit('change-amount', event);
        },
        findAnswer (answers, el) {
            return answers.find(a => a.id === el);
        },
        getQuestionType (answers, el) {
            return this.findAnswer(answers, el)?.question_type;
        },
        emitGetPDFFile () {
            this.$emit('get-pdf-file');
        },
        emitGetXLSFile () {
            this.$emit('get-xls-file');
        },
        showSendMail () {
            this.sendMailVisibility = true;
        },
        sendMail () {
            this.agency = this.host;
            this.kindOfReport = 'custom_report';
            this.report = this.filters;

            return this.$store.dispatch('sendMail/sendReport');
        },
        closeSendMail () {
            this.sendMailVisibility = false;
            this.$store.commit('sendMail/RESET_MAIL_DATA');
        }
    }
};
</script>

<style scoped>

</style>
